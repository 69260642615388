@keyframes pulse {
  0% {
    transform: scale(1);
  }
  50% {
    transform: scale(1.1);
  }
  100% {
    transform: scale(1);
  }
}

.lobbyAdminPanelContainer {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  padding: 1rem;

  button {
    background: #f44336;
    color: white;
    border: none;
    border-radius: 20px;
    font-size: 1.1em;
    text-align: center;
    cursor: pointer;
    transition: all 0.3s ease;
    box-shadow: 0px 8px 15px rgba(0, 0, 0, 0.1);

    &:hover {
      background: #d32f2f;
      transform: scale(1.05);
    }

    &:active {
      background: #c62828;
      box-shadow: 0px 5px 10px rgba(0, 0, 0, 0.1);
    }

    &.pulse {
      animation: pulse 1.5s infinite;
    }
  }
}
