.UNOButtonContainer {
    position: absolute;
    right: 50px;
    bottom: 50px;
    display: flex;
    justify-content: center;
    align-items: center;
    width: 200px;
    height: 100px;
    background: white;
    box-shadow: 0px 10px 0px 0px grey;
    border-radius: 50%;
    font-size: 64px;
    font-weight: 700;
    cursor: pointer
}