.card {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 125px;
    height: 200px;
    border-radius: 8px;
    overflow: hidden;

    span {
        position: absolute;
        font-size: 48px;
        color: white;
        pointer-events: none;
    }

    .back {
        color: black;
    }

    .centerCircle {
        z-index: -1;
        position: absolute;
        width: 130px;
        height: 130px;
        background: rgb(5,5,5);
        border-radius: 50%;
    }

    img {
        position: absolute;
        pointer-events: none;
    }

    .top {
        left: 8px;
        top: 11px;
        width: 20px;
    }

    .topNumber {
        display: block;
        width: 40px;
        text-align: center;
        left: 0;
        top: 2px;
        font-size: 24px;
        font-weight: 200;
    }

    .centerNumber {
        font-size: 82px;
        font-weight: 200;
    }

    .center {
        height: 60px;
    }

    .bottom {
        right: 10px;
        bottom: 8px;
        font-size: 26px;
        width: 24px;
    }

    .bottomNumber {
        display: block;
        width: 40px;
        text-align: center;
        right: 0;
        bottom: 2px;
        font-size: 24px;
        font-weight: 200;
    }
}

// transitions
.card-enter {
    opacity: 0;
}

.card-enter-active {
    opacity: 1;
    transition: opacity 1000ms;
}

.card-exit {
    opacity: 1;
}

.card-exit-active {
    opacity: 0;
    transition: opacity 1000ms;
}