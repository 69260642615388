.chatContainer {
  position: relative;
  width: 500px;
  height: 230px;
  background: white;
  border: 1px solid rgba(0, 0, 0, 0.5);

  .chatMessageContainer {
    overflow-y: scroll;
    height: calc(100% - 21px);

    .chatMessage {
      margin: 0;
      font-size: 16px;
      line-height: 24px;

      &:nth-child(odd) {
        background-color: rgb(245, 245, 245);
      }

      span {
        color: purple;
        margin-left: 5px;
      }
    }

    &::-webkit-scrollbar {
      width: 5px;
    }

    &::-webkit-scrollbar-track {
      background: transparent;
    }

    &::-webkit-scrollbar-thumb {
      background: rgba(20, 20, 20, 0.5);
    }
  }

  .messageInput {
    position: absolute;
    bottom: 0;
    margin: 0;
    padding: 0;
    width: 100%;
    height: 20px;
    border: 0px;
    border-top: 1px solid rgba(0, 0, 0, 0.5);

    &:focus {
      outline: none;
    }
  }
}

.chatContainer {
  display: flex;
  flex-direction: column;
  background: rgba(255, 255, 255, 0.8);
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
}

.chatMessageContainer {
  overflow-y: scroll;
}

.chatMessage {
  &.enterAnimation {
    opacity: 0;
    transform: translateY(50px);
    animation: enterAnimation 0.5s forwards ease-out;
  }

  @keyframes enterAnimation {
    to {
      opacity: 1;
      transform: translateY(0);
    }
  }
}

.messageInput {
  &.unoStyle {
    border: none;
    background: #ffffff;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
    transition: box-shadow 0.3s ease;

    &:focus {
      box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
    }
  }
}
