@import url("https://cdnjs.cloudflare.com/ajax/libs/animate.css/4.1.1/animate.min.css");

.homeContainer {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
  height: 100vh;
  justify-content: center;
  background: linear-gradient(45deg, #ec407a, #ab47bc);
  font-family: "Arial", sans-serif;

  h1 {
    font-size: 3em;
    color: white;
    text-shadow: 2px 2px 4px rgba(0, 0, 0, 0.7);
  }
}

.divider {
  display: flex;
  align-items: center;
  justify-content: center;
  margin: 20px 0;
  color: white;

  span {
    padding: 0 10px;
    font-size: 1.5em;
  }

  &::before,
  &::after {
    content: "";
    flex-grow: 1;
    height: 2px;
    background-color: white;
  }
}

button {
  padding: 15px 30px;
  font-size: 1.2em;
  margin: 10px;
  cursor: pointer;
  border: none;
  color: white;
  border-radius: 50px;
  box-shadow: 0px 8px 15px rgba(0, 0, 0, 0.1);
  transition: all 0.3s ease 0s;

  &:hover {
    box-shadow: 0px 15px 20px rgba(0, 0, 0, 0.2);
    transform: translateY(-7px);
  }
}

.createButton {
  background: linear-gradient(45deg, #4caf50, #8bc34a);
}

.joinButton {
  background: linear-gradient(45deg, #ff9800, #ffc107);
}

.roomInputContainer {
  .roomInput {
    padding: 10px;
    font-size: 1.2em;
    border: none;
    border-radius: 50px;
    width: 200px;
    background: rgba(255, 255, 255, 0.9);
    box-shadow: 0px 8px 15px rgba(0, 0, 0, 0.1);
    text-align: center;
  }
}
