@import url("https://cdnjs.cloudflare.com/ajax/libs/animate.css/4.1.1/animate.min.css");

.lobbyContainer,
.loadingContainer {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: 100vh;
  background: linear-gradient(45deg, #ec407a, #ab47bc);
  font-family: "Arial", sans-serif;
  color: black;

  .lobbyCode {
    font-size: 2.5em;
    position: absolute;
    z-index: 10;
    text-shadow: 2px 2px 4px rgba(0, 0, 0, 0.7);
  }
  .lobbyCode {
    font-size: 2.5em;
    position: absolute;
    z-index: 10;
    text-shadow: 2px 2px 4px rgba(0, 0, 0, 0.7);
    color: white;
  }

  .userCircleContainer {
    position: relative;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    width: 400px;
    height: 300px;
    z-index: 1;

    .userCircle {
      position: relative;

      .userIcon {
        display: flex;
        align-items: center;
        justify-content: center;
        position: absolute;
        width: 50px;
        height: 50px;
        border-radius: 25px;
        background: rgba(255, 255, 255, 0.2);
        text-align: center;
        box-shadow: 0px 8px 15px rgba(0, 0, 0, 0.1);
      }
    }
  }
}

.loadingContainer {
  font-size: 2.5em;
}
