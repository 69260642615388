.gameResultContainer {
  display: flex;
  justify-content: center;
  align-items: center;
  position: absolute;
  width: 100vw;
  height: 100vh;
  border-radius: 15px;

  &:after {
    content: " ";
    width: 100%;
    height: 100%;
    background: rgba(0, 0, 0, 0.5);
  }

  .gameResult {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    position: absolute;
    width: 500px;
    height: 500px;
    background: white;
    border-radius: 15px;

    h1 {
      font-size: 72px;
      margin: 0;
    }
  }

  button {
    background-color: black;
  }
}
